<!--电能设置--基本设置---->
<template>
  <div id="basicsetup" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <div class="headertitle">
      <!-- 基本单位指标 -->
      <span> {{$t('energy.enedata163')}} </span>
    </div>
   
    <table class="table">
      <tr class="table_title">
        <td class="table_td">NO.</td>
        <!-- 名称 -->
        <td class="table_td">{{$t('energy.enedata164')}}</td>
        <!-- 单位 -->
        <td class="table_td">{{$t('energy.enedata165')}}</td>
        <!-- 输入方法 -->
        <td class="table_td">{{$t('energy.enedata166')}}</td>
        <!-- 计算方法 -->
        <td class="table_td">{{$t('energy.enedata167')}}</td>
        <!-- 全年值 -->
        <td class="table_td">{{$t('energy.enedata168')}}</td>
        <!-- 删除 -->
        <td class="table_td">{{$t('energy.enedata169')}}</td>
      </tr>

      <tr class="row" v-for="(item, index) in norms" :key="index">
        <td class="table_td">{{item.data.basicUnitNo}}</td>
        <td class="table_td">
          <a-input v-model="item.data.basicUnitName" :disabled="item.disable" :class="item.nameVail" />
        </td>
        <td class="table_td">
          <a-input v-model="item.data.unit" :disabled="item.disable"  :class="item.unitVail" />
        </td>
        <!-- inputType -->
        <td class="table_td"  v-if="item.data.inputType == 2">
          <!-- 手动输入 -->
          <a-button type="primary" ghost @click="inputTypeChange(index,2)" :disabled="item.disable" >{{$t('energy.enedata170')}}</a-button>
          <!-- 计算 -->
          <a-button  @click="inputTypeChange(index,1)" :disabled="item.disable" >{{$t('energy.enedata171')}}</a-button>
        </td>
         <td class="table_td"  v-if="item.data.inputType == 1">
          <a-button  @click="inputTypeChange(index,2)" :disabled="item.disable" >{{$t('energy.enedata170')}}</a-button>
          <a-button type="primary" ghost  @click="inputTypeChange(index,1)" :disabled="item.disable" >{{$t('energy.enedata171')}}</a-button>
        </td> 
        <!-- calcNo1 -->
        <td class="table_td">

          <a-select v-model="item.data.calcNo1" style="width: 100px;" :class="item.calcVail"
          :disabled="item.data.inputType == 2 || item.disable" validate-status="success">
                <a-select-option
                    v-for="(cn, i) in item.calc"
                    :key="i" :value="cn.id" >
                    {{ cn.text }}
                </a-select-option>
            </a-select>

            &nbsp;x&nbsp;

           <a-select v-model="item.data.calcNo2" style="width: 100px" :class="item.calcVail"
          :disabled="item.data.inputType == 2 || item.disable" >
                <a-select-option
                    v-for="(cn, i) in item.calc"
                    :key="i" :value="cn.id" >
                    {{ cn.text }}
                </a-select-option>
            </a-select>

         
        </td>
        <!-- yearFlag -->
        <td class="table_td" v-if="!item.data.yearFlag">
          <!-- 平均 -->
          <a-button type="primary" ghost @click="yearFlagChange(index,false)" :disabled="item.disable" >{{$t('energy.enedata172')}}</a-button>
          <!-- 累计 -->
          <a-button  @click="yearFlagChange(index,true)" :disabled="item.disable" >{{$t('energy.enedata008')}}</a-button>
        </td>
         <td class="table_td" v-if="item.data.yearFlag">
          <a-button  @click="yearFlagChange(index,false)" :disabled="item.disable" >{{$t('energy.enedata172')}}</a-button>
          <a-button type="primary" ghost @click="yearFlagChange(index,true)" :disabled="item.disable" >{{$t('energy.enedata008')}}</a-button>
        </td>
        <td class="table_td"><a-button type="primary" ghost :disabled="item.disable"  @click="delBasicSetup(index)">{{$t('energy.enedata169')}}</a-button></td>
      </tr>
     
    </table>
    <div class="button">
      <a-button type="primary" @click="search()">{{$t('energy.enedata128')}}</a-button>
      <a-button type="primary" @click="saveBasicSetup()">{{$t('energy.enedata173')}}</a-button>
    </div>

  </div>
</template>



<script>
import { getBasicSetupData ,deleteBasicSetupData , saveBasicSetupData } from "../../../api/energy";

   
export default {
  name: "basicsetup",
  data() {
    return {
      loading: false,
      norms:[],
      dataChange:false,
      errorMessage:[
          this.$t('energy.enedata163')                                             // 0
         ,this.$t('energy.enedata174')//"名称没有输入。"							              // 1
         ,this.$t('energy.enedata175')//"单位没有输入。"                            // 2
         ,this.$t('energy.enedata176')//"计算方法没有被选择。请选择计算方法。"        // 3
         ,this.$t('energy.enedata177')//"计算方法不能选择自己。"                     // 4
         ,this.$t('energy.enedata178')//"计算方法不能选择相同的项目。"               // 5
         ,this.$t('energy.enedata179')//"计算方法不能选择未注册的项目。"             // 6
         ,this.$t('energy.enedata180')//"数据注册成功。"                            // 7
         ,this.$t('energy.enedata181')//"数据注册失败。"                            // 8
         ,this.$t('energy.enedata182')//"数据删除成功！"                            // 9
         ,this.$t('energy.enedata183')//"数据删除失败！"                            // 10
         ,this.$t('energy.enedata184')//"确定要进行设定内容注册吗？" 
         ,this.$t('energy.enedata185')//"确定要进行删除操作吗？"   
         ,this.$t('energy.enedata186')//"确认要放弃所编辑的数据吗？"       
        ] 
    };
  },
  computed: {
    
  },
  async mounted() {
     
    this.loadBasicSetupData();
    
  },
  methods:{
    search(){
       if(this.dataChange){
        this.$confirm({
        title: this.errorMessage[13],
        centered: true,
        onOk: () => {
              this.loadBasicSetupData();
             }
        });
      }else{
          this.loadBasicSetupData();
      }
    },
    loadBasicSetupData(){
      let params = {
        clientId: this.$store.getters.clientId,
        siteId: this.$route.query.id,
        sitegrId: this.$store.getters.sitegrId,
        tenantId: 0,
      };
      console.log(params);
      this.loading = true;
    
      getBasicSetupData(params)
      .then((res) => {
        console.log(res);
        this.loading = false;
        let normList = [];
        if(res.errorCode == '00'){
         for (let i = 0; i < res.data.length; i++) {
            let elet = res.data[i];
            let disable = i==0;
            let no = i+1;
            let norm = {
              data:elet,
              calc:this.getCalcNoList(no),
              disable:disable,
              calcVail:"",
              nameVail:"",
              unitVail:""
            };
            normList.push(norm);
          }
        }else{
           normList = this.getDefaultData();
        }
         console.log(normList);
        this.norms = normList;
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },
    handleChange(){
      console.log("handleChange");
    },
    getCalcNoList(no){
       let calcModes = [];
       for (let k = 0; k < 9; k++) {
          if(k==0){
            calcModes.push({id:k,text:"　"});
          }else if(k!=no){
            calcModes.push({id:k,text:"No."+k});
          }
       }
       return calcModes;
    },
    getDefaultData(){
      let normList = [];
      for (let index = 0; index < 8; index++) {
        let no = index+1;
        let calcModes = this.getCalcNoList(no);
        let disable = index==0;
        let elet =  {
              id: 0,
              basicUnitNo: no,
              basicUnitName: "",
              unit: "",
              inputType: 2,
              calcNo1: 0,
              calcNo2: 0,
              yearFlag: false
        };
        let norm = {
              data:elet,
              calc:calcModes,
              disable:disable,
              calcVail:"",
              nameVail:"",
              unitVail:""
        };

        normList.push(norm);
      }
      return normList;
    },
    inputTypeChange(index,val){
      console.log("inputTypeChange>>"+index);
      this.norms[index].data.inputType = val;
      this.dataChange = true;
    },
    yearFlagChange(index,bol){
      console.log("yearFlagChange>>"+index);
      this.norms[index].data.yearFlag = bol;
      this.dataChange = true;
    },
    delBasicSetup(index){ 
      this.$confirm({
        title: this.errorMessage[12],
        centered: true,
        onOk: () => {
                let nrm = this.norms[index].data;
                console.log(nrm);
                let params = {
                  action:3,
                  clientId: this.$store.getters.clientId,
                  siteId: this.$route.query.id,
                  sitegrId: this.$store.getters.sitegrId,
                  tenantId: 0,
                  id:nrm.id
                };
                console.log(params);
                this.loading = true;
                deleteBasicSetupData(params)
                .then((res) => {
                  let msg = '';
                  if(res.errorCode=="00"){
                    msg = this.errorMessage[0]+this.errorMessage[9];
                    this.loadBasicSetupData();
                    this.$message.success(msg);
                  }else{
                    msg = this.errorMessage[0]+this.errorMessage[10];
                    this.$message.error(msg);
                  }
                })
                .catch((err) => {
                  this.loading = false;
                  console.log(err);
                });    
            }
        });    
    },
    saveBasicSetup(){
      let err = 0;
      let msg = '';
      let paramArr = [];
      for (let k = 0; k < this.norms.length; k++) {
        this.norms[k].nameVail = '';
        this.norms[k].unitVail = '';
        this.norms[k].calcVail = '';
      }
      for (let i = 0; i < this.norms.length; i++) {
        let ele = this.norms[i].data;
        console.log(ele);
        let vail = false;
        if(ele.basicUnitNo>1){
          if(ele.id>0){
              vail = true;
          }else {
            if(ele.basicUnitName !='' || ele.unit != '' || ele.inputType==1 
              || ele.calcNo1>0 || ele.calcNo2>0){
              vail = true;
            }
          }
        }
        if(vail){
          let em = this.vailString(this.$t('energy.enedata164'),ele.basicUnitName,1,30);
          if(em !==''){
              this.norms[i].nameVail = 'error';
              msg = em;
              err = 99;
              break;
          }
          em = this.vailString(this.$t('energy.enedata165'),ele.unit,1,30);
          if(em !=''){
              this.norms[i].unitVail = 'error';
              msg = em;
              err = 99;
              break;
          }

          if(ele.inputType==1){
            if(ele.calcNo1==0 || ele.calcNo2==0){
              this.norms[i].calcVail = 'error';
              err = 3;
              break;
            }

             if(ele.calcNo1==ele.basicUnitNo || ele.calcNo2==ele.basicUnitNo){
              this.norms[i].calcVail = 'error';
              err = 4;
              break;
            }
            
            if(ele.calcNo1 == ele.calcNo2){
               this.norms[i].calcVail = 'error';
              err = 5;
              break;
            }
            
            if(this.inVailCalc(ele.calcNo1) || this.inVailCalc(ele.calcNo2)){
               this.norms[i].calcVail = 'error';
              err = 6;
              break;
            }

          }
         
        }

        if(i==0 || vail)
          paramArr.push(ele);
          
      }
      if(err>0) {
        if(err==99){
          
        }else{
          msg = this.errorMessage[0]+this.errorMessage[err];
        }
        this.$message.error(msg);
        return;
      }
      this.$confirm({
        title: this.errorMessage[11],
        centered: true,
        onOk: () => {
              this.loading = true;
              let params = {
                action:2,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                data:paramArr
              }
              console.log(params);
              saveBasicSetupData(params)
              .then((res) => {
                let msg = '';
                if(res.errorCode=="00"){
                  msg = this.errorMessage[0]+this.errorMessage[7];
                  this.loadBasicSetupData();
                  this.$message.success(msg);
                }else{
                    msg = this.errorMessage[0]+this.errorMessage[8];
                  this.$message.error(msg);
                }
              })
              .catch((err) => {
                this.loading = false;
                console.log(err);
              });
             }
        });
    },
    inVailCalc(calc){
       let no = calc - 1;
       if(this.norms[no]==null) return true;
       if(this.norms[no].data.id==0) return true;
    },
    vailString(name,value,min,max){
        let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
        let code = '';
        if(''===value){
          code = "energy.enedata307";
        } else if(value.length<min || value.length>max){
          let msg = this.$t("energy.enedata308");
          msg = msg.replace("{text}",name);
          msg = msg.replace("{star}",min);
          msg = msg.replace("{end}",max);
           return msg;
        } else if(regEn.test(value)) {
           code = "energy.enedata309";
        } else {
           return "";
        }

        let msg = this.$t(code);
        msg = msg.replace("{text}",name);
        return msg;
    }
  },
};
</script>

<style scoped>

#basicsetup {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  overflow-y: auto;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'

}
.headertitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.headertitle::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  margin-right: 20px;
  background-color: #7682ce;
}
.table {
  width: 100%;
  font-size: 14px;
}
.table_title {
  background-color: #f2f4f7;
}
.table_td {
  padding: 15px;
}
.row {
  border-bottom: 1px solid #e7e8e8;
}
.button{
    display: flex;
    justify-content: center;
    margin-top: 30px    ;
}
input {
  width: 120px;
}
button {
  width: 120px;
  margin: 0 5px;
}
.error{
  border: 1px solid #FF0000;
}
</style>